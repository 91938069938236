var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.lang.label)+" Section")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"en.question","rules":"","name":"The Question"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Question","name":"question","fou":""},model:{value:(_vm.formData.question),callback:function ($$v) {_vm.$set(_vm.formData, "question", $$v)},expression:"formData.question"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"en.answer","rules":"","name":"The Answer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-text',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Answer","name":"answer","fou":""},model:{value:(_vm.formData.answer),callback:function ($$v) {_vm.$set(_vm.formData, "answer", $$v)},expression:"formData.answer"}})]}}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }